import React from "react";
import "./InstagramPost.css";

const InstagramPost = (props) => {
  return (
    <div className="ig-post">
      <li>
        <img src={props.img}></img>
      </li>
    </div>
  );
};

export default InstagramPost;
