import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as CameraIcon } from "feather-icons/dist/icons/camera.svg";
import { ReactComponent as VideoIcon } from "feather-icons/dist/icons/video.svg";
import HomeWeddingPhoto from "images/HomeWeddingPhoto.jpg";
import floral_flame from "images/floral-frame.jpg";

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-[#080808] bg-center bg-cover`}
  background-image: url('images/floral-frame.jpg');
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-no-repeat bg-center h-full shadow-lg`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left font-sans`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 text-white text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4  text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-white`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-teal-700 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-teal-600`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-teal-600`;
const FeatureDescription = tw.div`mt-1 text-white text-sm`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = "",
  heading = <>What is BRAZN?</>,
  description = "Brazn is a production company that specializes in capturing significant moments. Whether it's for businesses or personal occasions, we are passionate about creating cinematic videos and taking stunning photography. Located in the Waterloo Region, our dedicated team is committed to delivering exceptional service and ensuring that your message is delivered in a beautiful and unforgettable manner.",
  primaryButtonText = "See Our Portfolio",
  primaryButtonUrl = "https://timewrse.com",
  features = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: CameraIcon,
      title: "Photography",
      description:
        "Our packages include hundreds of photos captured for your business or special night!",
    },
    {
      Icon: VideoIcon,
      title: "Videography",
      description:
        "From cinematic films to highlight reels, we capture what you need and transform it into a powerful message.",
    },
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={HomeWeddingPhoto} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>
                    {<feature.Icon />}
                  </FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>
                      {feature.description}
                    </FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
