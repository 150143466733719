import React from "react";
import { Link } from "react-router-dom";
import braznlogo from "./Brazn_white.png";
import "../../styles/Base.css";

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-titles-wrapper">
        <div className="home-socials-wrapper">
          <a
            href="https://www.youtube.com/channel/UCqTlen9lMsJfuF84UPSOBbg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ion-icon name="play-outline"></ion-icon>
          </a>
          <a
            href="https://www.instagram.com/bebrazn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
        </div>
        <img className="home-title" src={braznlogo} />
        <h2 className="home-subtitle">Cinematic Film & Photography</h2>
        <div className="home-buttons-wrapper">
          <ul>
            <li>
              <Link to="/booking" className="button">
                <div className="button__line"></div>
                <div className="button__line"></div>
                <span className="button__text">BOOK NOW</span>
                <div className="button__drow1"></div>
                <div className="button__drow2"></div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="home-div">
        <div class="video-container">
          <iframe
            className="home-iframe"
            src="https://www.youtube.com/embed/MuID2-dopyY?&controls=0&loop=1&autoplay=1&mute=1&playsinline=1&playlist=MuID2-dopyY"
          ></iframe>
        </div>
        <div className="home-div-wrapper"></div>
      </div>
    </div>
  );
};

export default Home;
