import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/BookWeddingPhoto.jpg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { ReactComponent as ConfirmIcon } from "feather-icons/dist/icons/check-circle.svg";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { Link } from "react-router-dom";

const Container = tw(
  ContainerBase
)`min-h-screen bg-gray-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-2xl font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Title = styled.h1`
  ${tw`text-3xl lg:text-center sm:text-4xl lg:text-5xl xl:text-4xl font-black text-gray-800 leading-none`}
`;

const ConfirmationScreen = tw.div``;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-orange-600 text-gray-100 w-full py-4 rounded-lg hover:bg-orange-800 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const BackButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-red-600 text-gray-100 w-full py-4 rounded-lg hover:bg-red-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const ConfirmButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-green-600 text-gray-100 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => ` background-image: url("${props.imageSrc}");`}
  ${tw`w-full bg-cover bg-no-repeat object-right`}
`;

const ErrorText = tw.p`text-sm text-red-600 text-left`;

const Login = ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Book Now",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign In With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign In With Twitter",
      url: "https://twitter.com",
    },
  ],
  submitButtonText = "Submit",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  signupUrl = "#",
}) => {
  const [fullNameField, setFullNameField] = useState();
  const [emailField, setEmailField] = useState();
  const [phoneNumberField, setPhoneNumberField] = useState();

  const [weddingDayField, setWeddingDayField] = useState();

  const [packageField, setPackageField] = useState("Base");

  const [fullNameError, setFullNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState();

  const [weddingDayError, setWeddingDayError] = useState();
  const [emailJSerror, setEmailJSError] = useState();

  const [formState, setFormState] = useState(0);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3gx36k1",
        "template_tnjdlvs",
        e.target,
        "CbH_VDrFNDq4823nB"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormState(2);
        },
        (error) => {
          setEmailJSError(
            "There was an error sending your request. Please try again. If the error persists, please reach out to us by email or phone: admin@brazn.ca - 226-218-8025"
          );
        }
      );
    e.target.reset();
  };

  const submitButton = (e) => {
    e.preventDefault();
    if (!fullNameField) {
      setFullNameError("Name must be entered before submission");
    }
    if (!emailField) {
      setEmailError("Email must be entered before submission");
    }
    if (!phoneNumberField) {
      setPhoneNumberError("Phone number must be entered before submission");
    }
    if (!weddingDayField) {
      setWeddingDayError("Day must be selected before submission");
    }
    if (
      fullNameField &&
      emailField &&
      phoneNumberField &&
      weddingDayField &&
      packageField
    ) {
      setFormState(1);
      setFullNameError();
      setEmailError();
      setPhoneNumberError();
      setWeddingDayError();
    }
  };

  const confirmButton = (e) => {
    sendEmail(e);
  };

  const backButton = () => {
    setFormState(0);
    setEmailJSError("");
  };

  const bookingForm = (
    <FormContainer>
      <Form onSubmit={submitButton}>
        <p tw="mt-8 text-sm text-gray-600 text-left">Personal Details</p>
        <Input
          type="text"
          placeholder="Full Name"
          name="fullName"
          value={fullNameField}
          onChange={(e) => setFullNameField(e.target.value)}
        />
        <ErrorText>{fullNameError}</ErrorText>
        <Input
          type="email"
          placeholder="Email"
          name="email"
          value={emailField}
          onChange={(e) => setEmailField(e.target.value)}
        />
        <ErrorText>{emailError}</ErrorText>
        <Input
          type="number"
          placeholder="Phone Number"
          name="phoneNumber"
          value={phoneNumberField}
          onChange={(e) => setPhoneNumberField(e.target.value)}
        />
        <ErrorText>{phoneNumberError}</ErrorText>
        <p tw="mt-8 text-sm text-gray-600 text-left">Package</p>
        <Select
          placeholder="Package"
          name="package"
          value={packageField}
          onChange={(e) => setPackageField(e.target.value)}
        >
          <option>Base - $2500</option>
          <option>Standard - $4000</option>
          <option>Rose - $5500</option>
          <option>Business</option>
        </Select>
        <p tw="mt-8 text-sm text-gray-600 text-left">
          {packageField === "Business" ? "Day Available" : "Wedding Day"}
        </p>
        <Input
          type="date"
          placeholder="Day of wedding"
          name="weddingDay"
          value={weddingDayField}
          onChange={(e) => setWeddingDayField(e.target.value)}
        />
        <ErrorText>{weddingDayError}</ErrorText>
        <SubmitButton type="submit">
          <SubmitButtonIcon className="icon" />
          <span className="text">{submitButtonText}</span>
        </SubmitButton>
      </Form>
      <p tw="mt-8 text-sm text-gray-600 text-center">
        Once you send your booking information, we will contact you within 48
        hours to discuss!
      </p>
    </FormContainer>
  );
  const bookingReview = (
    <FormContainer>
      <Form onSubmit={confirmButton}>
        <p tw="mt-8 text-sm text-gray-600 text-center">
          Please review your booking details:
        </p>
        <p tw="mt-8 text-sm text-gray-600 text-left">Personal Details</p>
        <Input
          type="text"
          placeholder={fullNameField}
          name="fullName"
          value={fullNameField}
          onChange={(e) => (e.target.value = fullNameField)}
        />
        <ErrorText>{fullNameError}</ErrorText>
        <Input
          type="email"
          placeholder={emailField}
          name="email"
          value={emailField}
          onChange={(e) => (e.target.value = emailField)}
        />
        <ErrorText>{emailError}</ErrorText>
        <Input
          type="number"
          placeholder={phoneNumberField}
          name="phoneNumber"
          value={phoneNumberField}
          onChange={(e) => (e.target.value = phoneNumberField)}
        />
        <ErrorText>{phoneNumberError}</ErrorText>
        <p tw="mt-8 text-sm text-gray-600 text-left">Package</p>
        <Select
          placeholder={packageField}
          name="package"
          value={packageField}
          onChange={(e) => (e.target.value = packageField)}
        >
          <option>Base</option>
          <option>Standard</option>
          <option>Rose</option>
          <option>Business</option>
        </Select>
        <p tw="mt-8 text-sm text-gray-600 text-left">
          {packageField === "Business" ? "Day Available" : "Wedding Day"}
        </p>
        <Input
          type="date"
          placeholder={weddingDayField}
          name="weddingDay"
          value={weddingDayField}
          onChange={(e) => (e.target.value = weddingDayField)}
        />
        <ErrorText>{weddingDayError}</ErrorText>
        <BackButton onClick={backButton}>
          <span className="text">Back</span>
        </BackButton>
        <ConfirmButton type="submit">
          <SubmitButtonIcon className="icon" />
          <span className="text">Confirm & Submit</span>
        </ConfirmButton>
        <p tw="mt-8 text-sm text-red-600 text-center">{emailJSerror}</p>
      </Form>
      <p tw="mt-8 text-sm text-gray-600 text-center">
        Once you send your booking information, we will contact you within 48
        hours to discuss!
      </p>
    </FormContainer>
  );
  const bookingComplete = (
    <FormContainer>
      <Form onSubmit={confirmButton}>
        <p tw="mt-8 text-sm text-gray-600 text-left">Personal Details</p>
        <Input
          type="text"
          placeholder={fullNameField}
          name="fullName"
          value={fullNameField}
          onChange={(e) => setFullNameField(e.target.value)}
          disabled
        />
        <ErrorText>{fullNameError}</ErrorText>
        <Input
          type="email"
          placeholder={emailField}
          name="email"
          value={emailField}
          onChange={(e) => setEmailField(e.target.value)}
          disabled
        />
        <ErrorText>{emailError}</ErrorText>
        <Input
          type="number"
          placeholder={phoneNumberField}
          name="phoneNumber"
          value={phoneNumberField}
          onChange={(e) => setPhoneNumberField(e.target.value)}
          disabled
        />
        <ErrorText>{phoneNumberError}</ErrorText>
        <p tw="mt-8 text-sm text-gray-600 text-left">Package</p>
        <Select
          placeholder={packageField}
          name="package"
          value={packageField}
          onChange={(e) => setPackageField(e.target.value)}
          disabled
        >
          <option>Base</option>
          <option>Standard</option>
          <option>Rose</option>
          <option>Business</option>
        </Select>
        <p tw="mt-8 text-sm text-gray-600 text-left">
          {packageField === "Business" ? "Day Available" : "Wedding Day"}y
        </p>
        <Input
          type="date"
          placeholder={weddingDayField}
          name="weddingDay"
          value={weddingDayField}
          onChange={(e) => setWeddingDayField(e.target.value)}
          disabled
        />
        <ErrorText>{weddingDayError}</ErrorText>
        <Link to="/">
          <ConfirmButton>
            <ConfirmIcon />
            <span className="text">Return</span>
          </ConfirmButton>
        </Link>
      </Form>
      <p tw="mt-8 text-sm text-gray-600 text-center">
        Your booking information has been submitted! We will contact you via
        email and phone within the next 48 hours!
      </p>
    </FormContainer>
  );
  return (
    <AnimationRevealPage disabled>
      <Container>
        <Content>
          <MainContainer>
            <Title>BRAZN</Title>
            <MainContent>
              <Heading>{headingText}</Heading>
              {formState === 0
                ? bookingForm
                : formState === 1
                ? bookingReview
                : bookingComplete}
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};

export default Login;
