import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import InstagramPost from "./InstagramPost";
import "./InstagramPost.css";

const InstagramWidget = () => {
  const [instagramprofile, setInstagramProfile] = useState();

  useEffect(() => {
    axios.get("https://feeds.behold.so/K7GwyWfBuAdT37sjO5hE").then((res) => {
      console.log(res);
      const response = res.data.map((item) => item);
      setInstagramProfile(response);
    });
  }, []);
  return (
    <div className="instagram-widget">
      <ul className="instagram-widget-list">
        {instagramprofile &&
          instagramprofile.map((item) => (
            <InstagramPost
              img={
                item.mediaType === "VIDEO" ? item.thumbnailUrl : item.mediaUrl
              }
            ></InstagramPost>
          ))}
      </ul>
      <div className="portfolio-buttons-wrapper">
        <ul>
          <li>
            <a
              href="https://www.instagram.com/bebrazn/"
              target="_blank"
              rel="noopener noreferrer"
              className="button"
            >
              <div className="button__line"></div>
              <div className="button__line"></div>
              <span className="button__text">VIEW INSTAGRAM</span>
              <div className="button__drow1"></div>
              <div className="button__drow2"></div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InstagramWidget;
